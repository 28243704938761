<template>
  <div class="user-group__item">
    <div class="user-group__content">
      <div class="user-group__icon">
        <img src="@/assets/images/icon-group2.svg" alt="">
      </div>
      <div class="user-group__head">
        <div class="user-group__name">
          <h3>{{ group.name }}</h3>
        </div>
        <div class="user-group__ribbon"><span class="crop-text">{{ typeText }}</span></div>
      </div>
      <div class="user-group__body">
        <div class="user-group__body-text">
          <ul class="user-group__body-info">
            <li style="display: flex; align-items: center;">
              <div class="cover">
                <span
                             v-if="unreadGroups && unreadGroups.feed && unreadGroups.feed.length"
                                 class="message_badge group_badge"
                                 :class="getClassBadgeLength(unreadGroups.feed.length)"
                                 title="Unread feeds"
                               >
                {{ unreadGroups.feed.length }}
                </span>
              </div>

              <router-link
                :to="{name: 'profileGroupsView', params: { id: group.slug }}"
              ><strong style="margin-right: 3px;">{{ group.cnt_post }}</strong> Posts
              </router-link>
            </li>
            <li style="display: flex; align-items: center;">
              <div class="cover">
                <span
                    v-if="unreadGroups && unreadGroups.members && unreadGroups.members.length"
                                         class="message_badge group_badge"
                                         :class="getClassBadgeLength(unreadGroups.members.length)"
                                         title="Unread members"
                                       >
                {{ unreadGroups.members.length }}
                </span>
              </div>
              <router-link
                :to="{name: 'profileGroupMembers', params: { id: group.slug }}"
              ><strong style="margin-right: 3px;">{{ group.cnt_user }}</strong> Members
              </router-link>
            </li>
            <li style="display: flex; align-items: center;">
              <div class="cover">
                <span
                    v-if="unreadGroups && unreadGroups.projects && unreadGroups.projects.length"
                                 class="message_badge group_badge"
                                 :class="getClassBadgeLength(unreadGroups.projects.length)"
                                 title="Unread projects"
                               >
                {{ unreadGroups.projects.length }}
                </span>
              </div>
              <router-link
                :to="{name: 'profileGroupStrategyProjects', params: { id: group.slug }}"
              >
                <strong style="margin-right: 3px;">{{ group.cnt_project }}</strong> Strategy
                Projects
              </router-link>
            </li>
          </ul>
          {{ group.description }}
        </div>
      </div>
    </div>
    <div v-if="group.status !== 'request'" class="user-group__more-box">
      <router-link
        :to="{ name: 'profileGroupsView', params: { id: group.slug } }"
        class="user-group__more-btn">
        <i class="icon icon-profile"></i>View group
      </router-link>
    </div>
  </div>
</template>
<script>
import badge from '@/mixins/badge';

export default {
  name: 'ItemGroup',
  mixins: [badge],
  props: {
    group: {
      type: Object,
      required: true,
    },
    unreadGroups: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    typeText() {
      return this.group.status === 'request' ? 'the group is awaiting confirmation' : this.group.type;
    },
  },
};
</script>
<style lang="scss" scoped>
.message_badge {
  &.two {
    width: 30px;
    //border-radius: 40%;
  }

  &.three {
    width: 40px;
    //border-radius: 40%;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  position: absolute;
  top: -10px;
  right: -10px;

  background-color: red;
  border-radius: 20px;

  z-index: 1;

  color: #fff;
  font-size: 12px;
  font-weight: 600;

  @media (max-width: 992px) {
    right: -20px;
  }
}

.group_badge {
  display: inline-flex;
  position: relative;
  top: auto;
  right: auto;

  //margin-right: 10px;
}

.cover {
  min-width: 40px;
  margin-right: 10px;
  text-align: right;
  margin-left: -17px;
}

//.user-group__grid .user-group__body-text {
//  max-height: 330px;
//  margin-bottom: 40px;
//}

</style>
