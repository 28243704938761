export default {
  data() {
    return {
      pagination: {
        pageCurrent: 1,
        pageCount: 1,
        perPage: 15,
        total: 0,
      },
    };
  },
  methods: {
    setPagination(headers) {
      this.pagination = {
        pageCurrent: headers['x-pagination-current-page'],
        pageCount: +headers['x-pagination-page-count'],
        perPage: headers['x-pagination-per-page'],
        total: +headers['x-pagination-total-count'],
      };
    },
    setFirstPage() {
      this.pagination.pageCurrent = 1;
    },
    addNewItemPagination() {
      this.pagination.total += 1;
      this.pagination.pageCount = Math.ceil(this.pagination.total / this.pagination.perPage);
    },
  },
};
