<template>
  <div class="user-cabinet__inner">
    <div class="user-cabinet__area w-95">
      <Alert :variant="variant" v-if="text">{{ text }}</Alert>
      <template v-if="groups.length">
        <div class="user-cabinet__area-top">
          <router-link
            class="btn btn-primary"
            :to="{ name: 'profileGroupsRequest' }"
          >
            Request a new group
          </router-link>
        </div>
        <!--        <transition-group v-if="groups.length" name="slide-fade" tag="ul"-->
        <!--                          class="user-group__list user-group__grid">-->
        <div v-if="groups.length" class="user-group__list user-group__grid">
          <ItemGroup v-for="group in groups"
                     :key="group.id"
                     :group="group"
                     :unreadGroups="unreadGroups.find((gr) => gr.id === group.id)"
          />
        </div>
        <!--        </transition-group>-->
      </template>
      <p v-else-if="groupsLoaded" v-html="content.block[0]"></p>
    </div>
  </div>
</template>
<script>
import GroupService from '@/services/GroupService';

import ItemGroup from '@/views/profile/MyGroups/ItemGroupV2.vue';
import Alert from '@/components/Alert.vue';
import pagination from '@/mixins/pagination';
import exception from '@/mixins/exception';
import content from '@/mixins/content';
import alert from '@/mixins/alert';
import badge from '@/mixins/badge';

export default {
  name: 'MyGroups',
  mixins: [pagination, exception, content, alert, badge],
  components: {
    ItemGroup,
    Alert,
  },
  data() {
    return {
      groups: [],
      projectParams: {
        page: 1,
        perPage: 5,
      },
      totalPages: 1,
      groupsLoaded: false,
    };
  },
  computed: {
    unreadGroups() {
      return this.$store.getters.getUnreadGroups;
    },
  },
  created() {
    if (this.$route.params.type && this.$route.params.type === 'request') {
      this.showAndHideAlertSuccess('You placed request for your group successfully', 'success');
    }

    this.pagination = {
      pageCurrent: 1,
      pageCount: 1,
      perPage: 5,
      total: 0,
    };
    this.getGroups();
  },
  mounted() {
    window.addEventListener('scroll', async () => {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = document.documentElement;
      const footer = document.getElementsByClassName('footer');
      if (window.innerWidth <= 768
        && scrollTop + clientHeight >= scrollHeight - footer[0].scrollHeight) {
        await this.getGroups();
      }
      if (window.innerWidth > 768 && scrollTop + clientHeight >= scrollHeight) {
        await this.getGroups();
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', async () => {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = document.documentElement;
      const footer = document.getElementsByClassName('footer');
      if (window.innerWidth <= 768
        && scrollTop + clientHeight >= scrollHeight - footer[0].scrollHeight) {
        await this.getGroups();
      }
      if (window.innerWidth > 768 && scrollTop + clientHeight >= scrollHeight) {
        await this.getGroups();
      }
    });
  },
  methods: {
    async getGroups() {
      let res = null;
      if (this.projectParams.page <= this.totalPages) {
        try {
          res = await GroupService.groupsMy({
            page: this.projectParams.page,
            'per-page': this.projectParams.perPage,
          });
          this.groups.push(...res.data);
          this.totalPages = res.headers['x-pagination-page-count'];
          this.projectParams.page = +res.headers['x-pagination-current-page'] + 1;
          this.groupsLoaded = true;
        } catch (e) {
          // this.groups = [];
          this.handleException(e);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 768px) and (max-width: 992px) {
  .user-group__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
